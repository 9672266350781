<template>
  <svg viewBox="0 0 14 8">
    <path
      d="M7.00072 5.17168L11.9507 0.22168L13.3647 1.63568L7.00072 7.99968L0.636719 1.63568L2.05072 0.22168L7.00072 5.17168Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronIcon",
};
</script>
